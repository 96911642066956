import { Box, Button, Typography } from '@mui/material';
import { CustomPopup } from 'components';
import {  MouseEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { DescriptionWrapper } from 'components/descriptionWrapper/DescriptionWrapper';
import styles from './faq.module.scss';
import { useLocation } from 'react-router-dom';
import { useIsMobileSize } from 'shared';
import { ContentAccordion } from './contentAccordion/ContentAccordion';


export const Faq = () => {
    const { state } = useLocation();
    
    const [open, setOpen] = useState(state ? state.open : 0);
    const [isOpenPopup, setIsOpenPopup] = useState(false);

    const [bar, setBar] = useState<NodeListOf<Element> | null>(null);

    useEffect(()=> {
    const bars = document.querySelectorAll('.line');
        setBar(bars)
    },[]);


    const handleClosePopup = () => {
        setIsOpenPopup(false);
        bar?.forEach(bar => bar.classList.toggle('cross'))
    }

    const handleTogglePopup = () => {
        setIsOpenPopup(prev => !prev);
        bar?.forEach(bar => bar.classList.toggle('cross'));
    };


    const handleChangeActive = (e: MouseEvent<HTMLButtonElement>) => {
        setOpen(+e.currentTarget.id)
    }

    const handleChangeActiveMobile = (e: MouseEvent<HTMLButtonElement>) => {
        setOpen(+e.currentTarget.id);
        handleClosePopup()
    }
    
    const { isSizeMobile } = useIsMobileSize()

    return (
        <Box sx={{paddingBottom: '16vh'}}>
            <DescriptionWrapper>
                <Typography className={styles.title}>
                        FAQ
                </Typography>
                <Box className={styles.wrapPanelSelect}>
                    <CustomPopup 
                        isOpenPopup={isOpenPopup} 
                        title={open === 0 ? 'Переводы' : "ПОКУПКА/ПРОДАЖА USDT"} 
                        handleTogglePopup={handleTogglePopup}
                        panel={
                            <Box className={styles.container}>
                                <Box className={styles.categories}>
                                    <Button className={clsx(styles.category, open === 0 && styles.active)} onClick={handleChangeActiveMobile} id='0'>
                                        Переводы
                                    </Button>
                                    <Button className={clsx(styles.category,  open === 1 && styles.active)} onClick={handleChangeActiveMobile} id='1'>
                                        Покупка/продажа USDT
                                    </Button>
                                </Box>
                            </Box>
                        }/>
                </Box>
                <Box className={clsx(!isSizeMobile && styles.hiddenWeb)}>
                    <ContentAccordion open={open}/>
                </Box>
                <Box className={clsx(styles.container, isSizeMobile && styles.hiddenMobile)}>
                    <Box className={styles.categories}>
                        <Button className={clsx(styles.category, open === 0 && styles.active)} onClick={handleChangeActive} id='0'>
                            Переводы
                        </Button>
                        <Button className={clsx(styles.category,  open === 1 && styles.active)} onClick={handleChangeActive} id='1'>
                            Покупка/продажа USDT
                        </Button>
                    </Box>
                    <ContentAccordion open={open}/>
                </Box>
                {isOpenPopup && <Box className={styles.blur} />}
            </DescriptionWrapper>
        </Box>
    )
}

import { useEffect } from 'react'
import styles from './auth.module.scss'
import { Box, Button, Typography } from '@mui/material';
import {ReactComponent as LogoSmall} from 'assets/icon/logoSmall.svg'

export interface UserApiTg {
    first_name: string;
    last_name: string;
    id: string;
    username: string;
    photo_url: string;
}
export interface ApiTelegramResponse {
    user: UserApiTg;
}

export const Auth = (props: any) => {


    const { 
        handleNext, 
        handleOpenSuccess, 
        handleBack, 
        handleSubmit, 
        onSubmit, 
        handleBackTitle='НАЗАД',
        login,
    } = props;

    const name = process.env.REACT_APP_TG_NAME_BOT;

    useEffect(() => {
        const button = document.createElement('script');
        button.async = true;
        button.src = 'https://telegram.org/js/telegram-widget.js?22';
        button.setAttribute('data-telegram-login', name as string);
        button.setAttribute('data-size', 'large');
        button.setAttribute('data-radius', '10');
        button.setAttribute('data-request-access', 'write');
        button.setAttribute('data-onauth', 'onTelegramAuth(user)');
        
        const parent = document.getElementById('telegram-widget');
        parent?.appendChild(button)


        const handleSubmitData = () => {
            handleSubmit(onSubmit)();
        };

        window.onTelegramAuth = (user) => {
            sessionStorage.setItem('tgFirstName', user.first_name);
            sessionStorage.setItem('tgLastName', user.last_name);
            sessionStorage.setItem('tgId', `${user.id}`);
            sessionStorage.setItem('tgUsername', user.username);
            sessionStorage.setItem('tgPhotoUrl', user.photo_url);

            !!handleNext && handleNext();
            !!handleOpenSuccess && handleOpenSuccess();
            !!handleSubmit && handleSubmitData()
            !!login && login()
        }
        return () => {
            parent?.removeChild(button);
        }
    }, []);

    return (
        <Box className={styles.telegramWidget} >
            <Box className={styles.cardContent}>
                <LogoSmall />
                <Typography className={styles.cardTitle} variant='body1'>
                    Чтобы продолжить, авторизуйтесь через Telegram
                </Typography>
        
                <Typography className={styles.cardDescription} variant='subtitle2'>
                    Вы сможете получить уведомления о актуальном курсе и информацию о следующих шагах
                </Typography>
        
                <Box className={styles.buttons} >
                    <Box id="telegram-widget"/>
                    
                    {
                        handleBack && <Button variant='contained' color='secondary' onClick={handleBack} className={styles.buttonBack}>
                            {handleBackTitle}
                        </Button>
                    }
                </Box>
            </Box>
        </Box>
    )
}